import { Button, Modal, Input, Form, Select, message, Col, Row, DatePicker, Table } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { requestError } from '../../controllers/functions';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

const ParentAlternativeContactsModal = ({ show, setOpenModal, record, action, refresh }) => {

  const [form] = Form.useForm();
  const headers = { authorization : `Bearer ${localStorage.getItem("token")}`}
  
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [state, setState] = useState({});
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    if(show){
        form.resetFields();
        getParentAlternativeContacts()
    }
  }, [ show, form ]);

  if(show !== isModalVisible){
    setIsModalVisible(show);
  }

  const getParentAlternativeContacts = async () => {
    try {
        setLoading(true)
        const {data} = await axios.get(process.env.REACT_APP_API_URI+`/parents/alternative/contacts/${record?.id}`, {headers} );
        setContacts(data)
    } catch (error) {
        requestError({ error })
    }finally{
        setLoading(false)
    }
  }

  const saveAlternativeContact = (v) =>{
    axios.post(process.env.REACT_APP_API_URI+`/parents/alternative/contacts/${record?.id}`, v , {headers} ).then(res=>{
      message.success(res.data.res)
      setContacts([ ...contacts, res.data.contact ])
      form.resetFields()
      refresh();
    }).catch(error=>{
      requestError({error});
    }).finally(done=>{
        setLoading(false);
    })
  }

  const editAlternativeContact = (v) =>{
    axios.put(process.env.REACT_APP_API_URI+`/parents/alternative/contacts/${state?.id}`, v , {headers} ).then(res=>{
      message.success(res.data.res)
      setContacts([ ...contacts, res.data.contact ])
      form.resetFields()
      setState({ ...state, action : "create"})
      getParentAlternativeContacts();
    }).catch(error=>{
      requestError({error});
    }).finally(done=>{
        setLoading(false);
    })
  }

  const deleteRecord = (id) => {
    axios.delete(process.env.REACT_APP_API_URI+`/parents/alternative/contacts/${id}`, {headers} ).then( data => {
        message.success(data.data.res);
        getParentAlternativeContacts()
    }).catch( error => {
        requestError({ error })
    })
  }

  const [loading, setLoading] = useState(false)

  const onChange = (event) => {
    setState({ ...state, [event.target.name] : event.target.value });
  }

  return (
    <>
      <Modal 
      title={`PARENT ${ record?.name ? `${record?.name}` : ''} ALTERNATIVE CONTACTS`.toUpperCase() }
      open={isModalVisible} 
      onOk={()=>setOpenModal(false)} 
      onCancel={()=>setOpenModal(false)}
      loading = {loading}
      footer={[
        <Button key="cancel" type=""  onClick={()=>{setOpenModal(false)}} danger>
            Close
        </Button>
      ]}
      >
      <Form
      layout="vertical"
      className="row-col"
      onFinish={(e)=> {
        if( state?.action === "editing" ) {
            editAlternativeContact(e)
        }else{
            saveAlternativeContact(e)
        }
    }}
      id="parent-alternative-contact-form" 
      form={form}
      >
        <Row>
            <Table style={{width : "100%"}} 
            dataSource={contacts.map( c => ({
                ...c, 
                key : c.id,
                action : (<>
                <EditOutlined  
                style={{ color : "blue" , marginRight : "1rem", cursor : "pointer"}}
                onClick={(e) => {
                    form.setFieldsValue(c);
                    setState({ ...state, action : "editing", id : c?.id })
                }}
                />
                <DeleteOutlined 
                style={{color : "red", cursor : "pointer"}}
                onClick={() =>deleteRecord(c?.id)}
                />
                </>)
            }))} 
            columns={columns} />
        </Row>
        <Row>
          <Col span={12} >
            <Form.Item
            name = "name" 
            style={{marginRight: 5}}
            rules={[
                { required: true, message: "Please input your Parent Name!" },
            ]}>
              <Input  style={{ height : 32 }}  value={state?.name} onChange={onChange} name = "name" placeholder="Contact Label" />
            </Form.Item>
          </Col>
          <Col span={10} >
            <Form.Item
            name = "phone" 
            style={{marginRight: 5}}
            rules={[
                { required: true, message: "Enter your parent phone number!" },
            ]}>
              <Input  style={{ height : 32 }}  value={state?.phone} onChange={onChange} name = "phone" placeholder="Alternative Phone No." />
            </Form.Item>
          </Col>
          <Col span={2} >
          <Button
          style={{
            height : 33
          }}
          type="primary" 
          htmlType='submit'
          form='parent-alternative-contact-form'
          >Save</Button>
          </Col>
        </Row>
        </Form>


      </Modal>
    </>
  );
};

export default ParentAlternativeContactsModal;
  
  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'PHONE',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
    },
  ];
  