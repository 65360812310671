import { Button, Modal, Input, Form, Select, message, Col, Row, DatePicker } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { requestError } from '../../controllers/functions';
import { use } from 'react';

const OrganizationInviteSchoolModal = ({ show, setOpenModal, refresh }) => {

  const [form] = Form.useForm();
  const headers = { authorization : `Bearer ${localStorage.getItem("token")}`}
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [state, setState] = useState({});

  useEffect(() => {
    form.resetFields();
  }, [show ]);

  if(show !== isModalVisible){
    setIsModalVisible(show);
  }

  const verify = () => {
    setLoading(true);
    axios.get( process.env.REACT_APP_API_URI + "/organization/", {headers} ).then(({data}) => {
        setState(data)
    }).catch( error =>{
        requestError({error})
    }).finally(done=>{
        setLoading(false);
    })
  }
  useEffect( ()=>{
    verify()
  }, [])

  const [loading, setLoading] = useState(false)

  return (
    <>
    
      <Modal 
      title={`INVITE YOUR SCHOOL` }
      open={isModalVisible} 
      onOk={()=>setOpenModal(false)} 
      onCancel={()=>setOpenModal(false)}
      loading={loading}
      footer={[
        <Button key="cancel" type=""  onClick={()=>{setOpenModal(false)}}>
            Cancel
        </Button>   
      ]}
      >
      <Form
      layout="vertical"
      className="row-col"
      id="org-user-form" 
      form={form}
      >
        <Row>
            <Col span={24} >
                Share this ID: {state?.id}
            </Col>
        </Row>
        </Form>

      </Modal>
    </>
  );
};

export default OrganizationInviteSchoolModal;