

import { Button, Modal, TimePicker, Input, Form, Select, message, Col, Row, DatePicker,  } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';

import { requestError } from '../../controllers/functions';

const TimetableModal = ({ show, setOpenModal, refresh, timetable }) => {

  const [form] = Form.useForm();
  
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [classes, setClasses] = useState([]);
  const [state, setState] = useState({});

  useEffect(() => {
    form.resetFields();
   if(timetable) {
    form.setFieldsValue({
        ...timetable,
        startTime : dayjs( timetable?.startTime, "HH:mm:ss"),
        endTime : dayjs( timetable?.endTime, "HH:mm:ss"),
        classes : (timetable?.classes || []).map( c => c.classId )
    });
   }
    axios.get(process.env.REACT_APP_API_URI + "/classes/", {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}}).then(data =>{
        setClasses(data.data);
      }).catch(error =>{
          requestError({error});
      })
  }, [show]);

  if(show !== isModalVisible){
    setIsModalVisible(show);
  }

  const submit = (v) =>{
    
    axios.post(process.env.REACT_APP_API_URI+"/teacher/timetable/timetables", 
      {
        ...v,
        startTime : v?.startTime?.format("HH:mm:ss"),
        endTime : v?.endTime?.format("HH:mm:ss"),
      }
      , {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}} ).then(res=>{
        message.success(res.data.res)
        setOpenModal(false);
        refresh();
      }).catch(error=>{
        requestError({error});
      }).finally(done=>{
          setLoading(false);
      })

  }

  const edit = (v) =>{
    
    axios.put(process.env.REACT_APP_API_URI+`/teacher/timetable/timetables/${timetable?.id}`, 
      {
        ...v,
        startTime : v?.startTime?.format("HH:mm:ss"),
        endTime : v?.endTime?.format("HH:mm:ss"),
      }
      , {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}} ).then(res=>{
        message.success(res.data.res)
        setOpenModal(false);
        refresh();
      }).catch(error=>{
        requestError({error});
      }).finally(done=>{
          setLoading(false);
      })

  }

  const [loading, setLoading] = useState(false)
  

  return (
    <>
    
      <Modal 
      title={`ADD TIMETABLE` }
      open={isModalVisible} 
      onOk={()=>setOpenModal(false)} 
      onCancel={()=>setOpenModal(false)}
      footer={[
        <Button key="cancel" type=""  onClick={()=>{setOpenModal(false)}}>
            Cancel
        </Button>,
        <Button  key="submit" type="primary" htmlType="submit" form="timetableConfigForm" loading={loading}>
          Submit
        </Button>     
      ]}
      >
      <Form
      layout="vertical"
      className="row-col"
      onFinish={(v)=> {
        if(timetable) {
            edit(v)
        }else{
            submit(v)
        }
      } }
      id="timetableConfigForm" 
      form={form}
      >

        <Row>

          <Col xs={24} sm={24} md={14} lg={12} >
            <Form.Item
            name = "name" 
            style={{ marginRight: 5 }}
            rules={[
                { 
                  required: true, 
                  message: "Please enter timetable name!" 
                },
            ]}
            required
            >
              <Input style={{ height : 33 }}  name='name' placeholder='Enter timetable name'/>
            </Form.Item>
          </Col>
          
          <Col 
                xs={12} sm={12} md={6} lg={6} 
                style={{paddingLeft:5}} 
                >
                <Form.Item 
                    name={`startTime`}
                    rules={[
                    {
                        required: true,
                        message: "Required Field*",
                    },
                    ]}
                    required
                    >
                    <TimePicker format={"HH:mm"} style={{width : "100%", borderRadius : 6}}  onCalendarChange={(value)=>{setState({ ...state, startTime : value && value.format('HH:mm') }); }} placeholder="Start Time" />
                </Form.Item>
            </Col>

            <Col  
                xs={12} sm={12} md={6} lg={6} 
                style={{paddingLeft:5}}
                >
                <Form.Item 
                name={`endTime`}
                    rules={[
                    {
                        required: true,
                        message: "Required Field*",
                    },
                    ]}
                    required
                >
                    <TimePicker
                    format={"HH:mm"} 
                    style={{width : "100%", borderRadius : 6}}  
                    onCalendarChange={(value)=>{setState({ ...state, endTime : value && value.format('HH:mm') }); }} 
                    placeholder="End Time" 
                    />
                </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} >
            <Form.Item
                style={{marginRight: 5}}
                name={'classes'}
                rules={[
                    {
                        required: true,
                        message: "Required Field*",
                    },
                    ]}
                required
                >
                <Select
                    showSearch
                    mode='multiple'
                    onChange={(value)=>{
                        setState({ ...state, classes : value });
                    }}
                    value={state?.classes}
                    placeholder="Select classes under this timetable."
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={classes.map( c => ({ label : c?.name, value : c?.id }))}
                />
                
                </Form.Item>
            </Col>

        </Row>

        </Form>
      </Modal>
    </>
  );
};

export default TimetableModal;