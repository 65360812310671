import { Card , Form, Row, Col, Input, Button, message } from "antd";
import axios from "axios"
import { requestError } from "../controllers/functions";
import { useState } from "react";
import { Link, useHistory  } from "react-router-dom";
const OrganizationSignup = () => {

    const [form] = Form.useForm();
    const [ loading , setLoading ] = useState(false);
    const history = useHistory ();

    const validatePassword = (password) => {
        var pass = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,200}$/;
        return pass.test(password);
    };    

    const submit = async (v) => {
        try {
            setLoading(true);
            const { data } = await axios.post(process.env.REACT_APP_API_URI + "/organization/", v);
            form.resetFields();
            message.success(data?.res);
            history.push("/");
        } catch (error) {
            requestError(error); 
        } finally {
            setLoading(false);
        }
    };
    

    return <>
    <div style={{
        textAlign : "center",
        marginTop : "5rem",
        justifyContent : "center",
        display : "flex"
    }}>
        <div>
            <h3>Create Your Organization</h3>
            <Card 
            style={{ width : 320, padding : "1rem" }}
            >
                <Form  form={form} id="new-organization-signup" onFinish={submit} >
                    <Row>
                        <Col span={24}>
                            <Form.Item name="name" rules={[{ required: true, message: "Organization Name is required" }]}>
                                <Input placeholder="Organization Name" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="email" rules={[{ required :true, type: "email", message: "Invalid email format" }]}>
                                <Input placeholder="Organization Email" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="phone">
                                <Input placeholder="Organization Phone" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="password"
                                rules={[
                                    { required: true, message: "Password is required" },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            // if (!value) {
                                            //     return Promise.reject(new Error("Password is required"));
                                            // }
                                            if (!validatePassword(value)) {
                                                return Promise.reject(new Error("Password must be at least 6 characters, include an uppercase letter, a number, and a special character"));
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password placeholder="Password" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="confirmPassword"
                                dependencies={["password"]}
                                rules={[
                                    { required: true, message: "Please confirm your password" },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value) {
                                                return Promise.reject(new Error("Please confirm your password"));
                                            }
                                            if (getFieldValue("password") !== value) {
                                                return Promise.reject(new Error("Passwords do not match"));
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password placeholder="Confirm Password" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

                <Button loading={loading} htmlType="submit" form="new-organization-signup" type="primary" style={{ width : "100%", marginBottom : "1rem"}}> Submit </Button>
                <Link to="/" className="font-bold" style={{ color : "green"}}>
                    <Button loading={loading} htmlType="submit" form="new-organization-signup" type="green"  style={{ width : "100%", color : "green", fontWeight:900}}> Go to Login </Button>
                </Link>

            </Card>
        </div>
    </div>
    </>
}

export default OrganizationSignup;