import moment from "moment"
import DatePickerComponent from "../components/date/period";
import { useEffect, useState } from "react";
import { Button, Dropdown, message, Tag } from "antd";
import axios  from "axios"
import dayjs  from "dayjs"
import { requestError } from "../controllers/functions";
import {  ArrowLeftOutlined, ArrowRightOutlined, BuildFilled, CheckOutlined, CheckSquareFilled, CheckSquareOutlined, CloseOutlined, CloseSquareFilled, CloseSquareOutlined, DeleteFilled, DeleteOutlined, DownOutlined, EditOutlined, HomeOutlined, MenuOutlined, PlusCircleOutlined, ReloadOutlined, RobotOutlined, TeamOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import ButtonGroup from "antd/es/button/button-group";
import TimetableModal from "../components/modals/Timetable.add";
import TimetableAutoGenModal from "../components/modals/timetable.auto.gen";
import TimetableAddLessonModal from "../components/modals/timetable.lesson.add";

const Timetable = ({ permissions }) => {
    
    const [reportPeriod , setReportPeriod] = useState([ moment().startOf("week"), moment().endOf("week") ]);
    const [timetable, setTimetable ] = useState({});
    const [timetableConfig, setTimetableConfig ] = useState({});
    const [display, setDisplay ] = useState('byClass');
    const [timetableId, setTimetableId ] = useState(0);
    const [lesson, setLesson ] = useState({});
    const [showAddLessonModal, setShowAddLessonModal ] = useState(false);
    const [ selectedItems, setSelected ] = useState([]);
    const [ timetables, setTimetables ] = useState([]);
    const [ showAddTimetableModal, setShowAddTimetableModal ] = useState(false);
    const [ showAddTimetableAutoGenModal, setShowAddTimetableAutoGenModal ] = useState(false);
    
    const getTimetable = () => {
        if( display === "byClass") {
            axios.get(process.env.REACT_APP_API_URI+`/teacher/timetable/view/by/class?start=${reportPeriod[0]}&end=${reportPeriod[1]}&timetableId=${timetableId}` , {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}} ).then(res=>{
                setTimetable(res.data)
              }).catch(error=>{
                requestError({error});
              })
        } else{
            axios.get(process.env.REACT_APP_API_URI+`/teacher/timetable/view/by/teacher?start=${reportPeriod[0]}&end=${reportPeriod[1]}&timetableId=${timetableId}` , {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}} ).then(res=>{
                  setTimetable(res.data)
                }).catch(error=>{
                  requestError({error});
                })
        }
  
    }

    const getTimetables = () => {
        axios.get(process.env.REACT_APP_API_URI+`/teacher/timetable/timetables` , {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}} ).then(res=>{
            setTimetables(res.data);
            setTimetableId(res.data[0]?.id)
          }).catch(error=>{
            requestError({error});
          })
    }

    const calculateTotalBreakTime = ({ breaks }) => {
        return breaks
            .reduce((total, b) => {
                const start = moment(b.startTime, "HH:mm");
                const end = moment(b.endTime, "HH:mm");
                const duration = end.diff(start, 'minutes'); // Calculate duration in minutes
                return total + duration; // Accumulate durations
            }, 0); // Start with a total of 0 minutes
    };


    const generateLessonSlots = ({ lessonDuration, timetable }) => {

        const totalBreakTime = calculateTotalBreakTime({ breaks : timetable.breaks || [] });

        const startTime = moment( timetable?.timetable?.startTime, "HH:mm:ss");
    
        const endTime = moment( timetable?.timetable?.endTime, "HH:mm:ss");
    
        const slots = [];
        const totalLessons = (endTime.diff(startTime, 'minutes') - totalBreakTime) / lessonDuration;
    
        let currentStartTime = startTime.clone(); // Track start time for each slot
    
        for (let i = 1; i <= totalLessons; i++) {
            const iterationStartTime = currentStartTime.clone();
            const iterationEndTime = iterationStartTime.clone().add(lessonDuration, 'minutes');

            const nowGoingToBreak = ( timetable.breaks || [] ).find( breakTime => (
                iterationEndTime.isAfter(moment(breakTime?.startTime, "HH:mm")) &&
                iterationEndTime.isSameOrBefore(moment(breakTime?.endTime, "HH:mm"))
            ))
            
    
            if(nowGoingToBreak) {
                slots.push({
                    startTime: nowGoingToBreak.startTime,
                    endTime: nowGoingToBreak.endTime,
                    lessons : [],
                    breakName : nowGoingToBreak?.name
                });
                currentStartTime = moment(nowGoingToBreak?.endTime, "HH:mm"); // Update start time for next slot
            }else{
                 // Find lessons within the current slot
                const lessons = (display === "byClass" ? timetable.classes : timetable.teachers).reduce((accumulatedLessons, { timetable }) => {
                    const filteredLessons = timetable.filter(({ startTime, endTime }) => {
                        const lessonStart = moment(startTime, 'HH:mm:ss');
                        const lessonEnd = moment(endTime, 'HH:mm:ss');
                        return (
                            // Lesson starts or ends within the slot
                            (lessonStart.isSameOrAfter(iterationStartTime) && lessonStart.isBefore(iterationEndTime)) ||
                            (lessonEnd.isAfter(iterationStartTime) && lessonEnd.isSameOrBefore(iterationEndTime)) ||
                            // Lesson spans the entire slot
                            (lessonStart.isSameOrBefore(iterationStartTime) && lessonEnd.isSameOrAfter(iterationEndTime))
                        );
                    });
                    return [...accumulatedLessons, ...filteredLessons];
                }, []);
        
                slots.push({
                    startTime: iterationStartTime.format('HH:mm'),
                    endTime: iterationEndTime.format('HH:mm'),
                    lessons,
                });

                const nowGoingToBreakAfterThisLesson =  ( timetable.breaks || [] ).find( tb => 
                        iterationEndTime.isSame( moment(tb.startTime, "HH:mm")) 
                    )

                if (nowGoingToBreakAfterThisLesson) {
                    slots.push({
                        startTime: moment(nowGoingToBreakAfterThisLesson?.startTime, "HH:mm").format("HH:mm"),
                        endTime: moment(nowGoingToBreakAfterThisLesson?.endTime, "HH:mm").format("HH:mm"),
                        lessons : [],
                        breakName : nowGoingToBreakAfterThisLesson?.name
                    });
                    currentStartTime = moment(nowGoingToBreakAfterThisLesson?.endTime, "HH:mm")
                }else{
                    currentStartTime = iterationEndTime; // Update start time for next slot
                }

        
            }
           
        }
    
        return slots;
    };
    
    
    useEffect( getTimetable, [ display, reportPeriod, timetableId ] );
    useEffect( getTimetables, [display, reportPeriod] );

    const approveLessons = () => {

        axios.put(process.env.REACT_APP_API_URI+`/teacher/timetable/bulk/approve` , { selectedItems }, {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}} ).then(res=>{
          message.success(res.data?.res);
          setSelected([]);
          getTimetable();
        }).catch(error=>{
          requestError({error});
        })
  
      }

      const deleteLessons = () => {

        axios.post(process.env.REACT_APP_API_URI+`/teacher/timetable/bulk/delete` , { selectedItems }, {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}} ).then(res=>{
          message.success(res.data?.res);
          setSelected([]);
          getTimetable();
        }).catch(error=>{
          requestError({error});
        })

    }
      const deleteTimetable = ({id}) => {

        axios.delete(process.env.REACT_APP_API_URI+`/teacher/timetable/timetables/${id}` , {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}} ).then(res=>{
          message.success(res.data?.res);
          getTimetable();
          getTimetables();
        }).catch(error=>{
          requestError({error});
        })

    }

    const previousDates = () =>{
        setSelected([]);

        setReportPeriod([
        moment(reportPeriod[0]).subtract(7, "days"),
        moment(reportPeriod[1]).subtract(7, "days"),
        ])

    }

    const nextDates = () =>{
        setSelected([]);

        setReportPeriod([
        moment(reportPeriod[0]).add(7, "days"),
        moment(reportPeriod[1]).add(7, "days"),
        ])
    }

    return <div>
      <div>
        <div
            style={{
                marginRight : 8,
            }}
            className="timetable-actions"
            >
            <div className="timetable-action-buttons" 
            style={{ 
                display : "inline-flex", 
                justifyContent : "center",
                gap : 5
            }}
            >
                <Button.Group
                    style={{
                    height : 29
                    }}>
                    <Button
                    style={{
                        lineHeight : 0,
                        height : "inherit",
                        padding : 6
                    }}
                    onClick={previousDates}
                    >
                        <ArrowLeftOutlined/>
                    </Button>
                    <DatePickerComponent  
                    reportPeriod={reportPeriod} 
                    setReportPeriod={setReportPeriod} 
                    style={{ height: 29, width: 215, fontSize : 12 }}
                    showIcon ={false}
                    />
                    <Button
                    style={{
                        lineHeight : 0,
                        height : "inherit",
                        padding : 6
                    }}
                    onClick={nextDates}
                    >
                        <ArrowRightOutlined/>
                    </Button>
                    </Button.Group>

                <ButtonGroup
                style={{
                    height: 29,
                    lineHeight: "1"
                }}
                >
                    <Button
                    style={{
                        height: 29,
                        lineHeight: "1",
                        backgroundColor: "rgb(145 239 162)",
                        marginRight : 2
                    }}
                    title="Add new timetable"
                    onClick={() => {
                        setShowAddTimetableModal(true);
                        setTimetableConfig()
                    } }
                    > 
                    <PlusCircleOutlined/>
                    </Button>
                    <Dropdown
                        menu={{
                        items : timetables.map( t => ({
                            key: t.id,
                            label: (
                              <div 
                              style={{ cursor : "pointer", width : "100%"}}
                              onClick={()=>setTimetableId(t?.id)}
                              >
                                {t?.name}
                                <div 
                                style={{ 
                                    fontSize:11, 
                                    fontWeight : 100,
                                    width : "100%"
                                }}
                                >
                                    <span
                                    style={{
                                        marginRight : 8
                                    }}>
                                        {moment(t?.startTime, "HH:mm:ss").format("HH:mm")} - {moment(t?.endTime, "HH:mm:ss").format("HH:mm")} 
                                    </span>
                                    <span style={{ float : "right"}}>
                                        
                                        <Button
                                            style={{ 
                                                marginRight : 8 ,
                                                height : 20,
                                                padding : 7
                                            }}
                                            onClick={ (e) => {
                                                e.stopPropagation();
                                                setTimetableConfig(t);
                                                setShowAddTimetableModal(true);
                                            }}
                                            color="primary" variant="dashed"
                                        >
                                            <EditOutlined
                                            style={{
                                                fontSize : 9
                                            }}/>
                                        </Button>
                                        
                                        <Button
                                            style={{ 
                                                marginRight : 8 ,
                                                height : 20,
                                                padding : 7
                                            }}
                                            onClick={ (e) => {
                                                e.stopPropagation();
                                                deleteTimetable({id : t.id })
                                            }}
                                            color="danger" variant="dashed"
                                        >
                                            <DeleteOutlined
                                            style={{
                                                fontSize : 9
                                            }}/>
                                        </Button>

                                    </span>
                                </div>
                              </div>
                            ),
                          })),
                        }}
                        placement="bottomLeft"
                        arrow
                    >
                        <Button
                        style={{
                            height: 29,
                            lineHeight: "1",
                            backgroundColor: "rgb(145 239 162)",
                            marginRight : 0
                        }}
                        title="My timetable(s)"
                        >
                            <MenuOutlined />
                        </Button>
                    </Dropdown>
                </ButtonGroup>
            </div>

             <div className="timetable-action-buttons">
                <Button
                    style={{
                        height: 29,
                        lineHeight: "1",
                        backgroundColor: "rgb(145 239 162)",
                        marginRight : 5
                    }}
                    onClick={() => {
                        setTimetable([]);
                        setDisplay( prevDisplay => prevDisplay === "byClass" ? 'byTeacher' : 'byClass' )
                    }}
                    title={display === "byClass" ? "View timetable by Teacher" : "View timetable by class"}
                    >
                    {
                        display === "byClass" ?
                        <>
                            <TeamOutlined/>
                        </>
                        :
                        <>
                            <HomeOutlined/>
                        </>
                    }
                </Button>
                
                <Button
                    style={{
                        height: 29,
                        lineHeight: "1",
                        backgroundColor: "rgb(145 239 162)",
                        marginRight : 5
                    }}
                    onClick={() => getTimetable()}
                    title="Refresh timetable"
                    >
                    <ReloadOutlined/>
                </Button>

                <Button
                    style={{
                        height: 29,
                        lineHeight: "1",
                        backgroundColor: "rgb(145 239 162)",
                        marginRight : 5
                    }}

                    onClick={() => {
                        if( selectedItems.length === 0 ){
                            var checkedList = [];
                            (display === "byClass" ? timetable.classes : timetable.teachers).forEach( ({ timetable }) => {
                                checkedList = [ ...checkedList, ...timetable.map( t => t.id) ]
                            } )
                            setSelected(checkedList);
                        }else{
                            setSelected([])
                        }
                    }}

                    title="Select All lessons"
                    >
                    {
                        selectedItems.length > 0 ?
                        <CloseSquareOutlined style={{color : "red"}}/>
                        :
                        <CheckSquareOutlined/>
                    }
                </Button>

                <Button
                    style={{
                        height: 29,
                        lineHeight: "1",
                        backgroundColor: "rgb(145 239 162)",
                        marginRight : 5
                    }}
                    onClick={() => approveLessons()}
                    title="Approve selected lessons"
                    >
                    <CheckOutlined/>
                </Button>

                <Button
                    style={{
                        height: 29,
                        lineHeight: "1",
                        backgroundColor: "rgb(145 239 162)",
                        marginRight : 5
                    }}
                    onClick={() => deleteLessons()}
                    title="Delete Selected lessons"
                    danger
                    >
                    <DeleteFilled/>
                </Button>
                

                {permissions.some((x) => x.permission?.name === "Create_Timetable") && (
                    <Button
                    style={{
                        height: 29,
                        lineHeight: "1",
                        backgroundColor: "rgb(145 239 162)",
                    }}
                    onClick={() => {
                        setShowAddTimetableAutoGenModal(true)
                    }}
                    title="Auto Generate timetable"
                    >
                    <RobotOutlined/>
                    </Button>
                )}
             </div>
        </div>
      </div>

      <div style={{overflowX : "scroll", float : "left", width : "100%"}}>
        {
            display === "byClass"?
            <TimetableByClass 
            timetable={timetable} 
            setShowAddLessonModal = {({ show, data }) =>{
                setLesson(data);
                setShowAddLessonModal(show);
            }}
            generateLessonSlots={generateLessonSlots} 
            selectedItems={selectedItems}
            setSelected={setSelected}
            reportPeriod = {reportPeriod}
            />
            :
            <TimetableByTeacher 
            timetable={timetable} 
            generateLessonSlots={generateLessonSlots} 
            selectedItems={selectedItems}
            setSelected= {setSelected}
            />
        }
      </div>

      <TimetableModal
        show={showAddTimetableModal}
        setOpenModal={setShowAddTimetableModal}
        refresh={getTimetables}
        timetable = {timetableConfig}
      />

      <TimetableAutoGenModal
        show={showAddTimetableAutoGenModal}
        setOpenModal={setShowAddTimetableAutoGenModal}
        refresh={getTimetable}
        timetable = {timetableConfig}
      />

      <TimetableAddLessonModal
      show={showAddLessonModal}
      setOpenModal={setShowAddLessonModal}
      lesson = {lesson}
      timetableId = {timetableId}
      refresh = {getTimetable}
      />

    </div>
   }
  
   export default Timetable;

   
   const TimetableByTeacher = ({ timetable, generateLessonSlots, selectedItems, setSelected }) => {
    return <table border={1} className='student_results_slip-table' style={{ width: '100%' }}>
    <thead>
        <tr style={{ backgroundColor: 'aliceblue' }}>
            <th
                style={firstColumn}
                rowSpan={2}
            >
                Time
            </th>
            
            {(timetable?.teachers || []).map((c) => (
                <th
                colSpan={timetable.daysOfTheWeek.length}
                className='student_attendance_slip-th'
                style={{ 
                    padding: 2, fontSize: 11, textAlign: 'center' ,
                    borderRight: '2px solid black',
                    borderLeft: '2px solid black',
                }}
                >
                {c.name}
                </th>
            ))}
            <th
                style={{ padding: 4, fontSize: 12, width: 50 }}
                className='student_attendance_slip-th'
            >
                Totals
            </th>
        </tr>
        <tr style={{ backgroundColor: 'aliceblue' }}>
        {(timetable?.teachers || []).map(() =>
            ( timetable.daysOfTheWeek || []).map((day, index ) => (
            <th
                className='student_attendance_slip-th'
                style={{ 
                    padding: 2, fontSize: 11, textAlign: 'center' ,
                    borderLeft: index === 0  && '2px solid black',
                }}
            >
                {day.day}
            </th>
            ))
        )}
        </tr>
    </thead>

    <tbody>
        {
            generateLessonSlots({ daysOfTheWeek : timetable?.daysOfTheWeek , lessonDuration : timetable?.lessonDuration , timetable }).map( slot =>{

            const lessons = new Map(
                slot.lessons.map((ts) => [
                    `${moment(ts.date).format("ddd")}${ts.teacherId}${slot?.startTime}${slot?.endTime}`,
                    ts,
                ])
            );
            
            return <tr>
                <td
                style={{
                textAlign: 'left',
                left: 0,
                zIndex: 999,
                position: 'sticky',
                backgroundColor: 'white',
                }}
            >
               <div style={{ width : 70 }}> {slot?.startTime} - {slot?.endTime}</div>
            </td>
            {
                (timetable.teachers || []).map( c => (timetable.daysOfTheWeek || []).map( (day, index ) =>{

                    const lesson = lessons.get(`${day.day}${c?.id}${slot?.startTime}${slot?.endTime}`)

                    return <td
                    style={{
                        backgroundColor : slot?.breakName  ? 
                            "#cfc6c6"
                            :
                            lesson?.status === "approved"?
                            "rgb(145 239 162)"
                            :
                            lesson?.status === "attended"?
                            "#9f9fda"
                            :   
                            lesson?.status === "scheduled"?
                            "rgb(221 234 221)"
                            :
                            lesson?.status === "cancelled"?
                            "rgb(221 139 140)"
                            :
                            "",
                        borderLeft: index === 0  && '2px solid black',
                        cursor : "pointer"
                    }}
                    onClick={()=>selectedItems.includes(lesson?.id)? 
                        setSelected(selectedItems.filter( id => id !== lesson?.id ))
                        :
                        lesson?.id && setSelected([...selectedItems, lesson?.id])
                    }

                    >
                        {
                            (lesson || slot?.breakName) &&
                            <div 
                            style={{ 
                                fontSize : 11, 
                                width : 90 ,
                                border : selectedItems.includes(lesson?.id)  && '0.5px solid black',
                                borderRadius : 3,
                            }}
                            >{ 
                                slot?.breakName? slot?.breakName : `${lesson?.class?.code} - ${lesson?.subject?.code}` }</div>
                        }
                    </td>
                }))
                
            }
            </tr> 
            }
            )
        }
    </tbody>
    </table>
}
   const TimetableByClass = ({ timetable, generateLessonSlots, selectedItems, setSelected, setShowAddLessonModal, reportPeriod }) => {
    return <table border={1} className='student_results_slip-table' style={{ width: '100%' }}>
    <thead>
        <tr style={{ backgroundColor: 'aliceblue' }}>
            <th
                style={firstColumn}
                rowSpan={2}
            >
                Time
            </th>
            
            {(timetable?.classes || []).map((c) => (
                <th
                colSpan={timetable.daysOfTheWeek.length}
                className='student_attendance_slip-th'
                style={{ padding: 2, fontSize: 11, textAlign: 'center' , borderLeft: '2px solid black' }}
                >
                {c.code} 
                </th>
            ))}
            <th
                style={{ padding: 4, fontSize: 12, width: 50 }}
                className='student_attendance_slip-th'
            >
                Totals
            </th>
        </tr>
        <tr style={{ backgroundColor: 'aliceblue' }}>
        {(timetable?.classes || []).map(() =>
            ( timetable.daysOfTheWeek || []).map((day, index ) => (
            <th
                className='student_attendance_slip-th'
                style={{ 
                    padding: 2, fontSize: 11, textAlign: 'center',
                    borderLeft: index === 0  && '2px solid black',
                 }}
            >
                {day.day}
            </th>
            ))
        )}
        </tr>
    </thead>

    <tbody>
        {
            generateLessonSlots({ daysOfTheWeek : timetable?.daysOfTheWeek , lessonDuration : timetable?.lessonDuration , timetable }).map( slot =>{
    
            return <tr>
                <td
                style={{
                textAlign: 'left',
                left: 0,
                zIndex: 999,
                position: 'sticky',
                backgroundColor: 'white',
                }}
            >
               <div style={{ width : 70 }}> {slot?.startTime} - {slot?.endTime}</div>
            </td>
            {
                (timetable.classes || []).map( c => (timetable.daysOfTheWeek || []).map( (day, index ) =>{
                
                    const todayLessons =  slot.lessons.filter( lesson => moment(lesson?.date).format("ddd") === day.day && lesson.classId === c?.id )

                    return <td
                    style={{
                        borderLeft: index === 0  && '2px solid black',
                    }}
                    
                    >

                        {
                            slot?.breakName ? 
                            <Tag
                            style={{
                                background : "#cfc6c6",
                                width : "100%",
                                textAlign : "center"
                            }}>
                                {slot?.breakName}
                            </Tag>
                            :
                            ( todayLessons.length > 0 ) ?
                            <div
                            style={{
                                display: 'inline-block',
                                width : 50*todayLessons.length
                            }}>
                                {
                                    todayLessons.map( lesson => <Tag 
                                        onDoubleClick={() => setShowAddLessonModal({
                                            show : true,
                                            data : {
                                                startTime : dayjs(lesson.startTime, "HH:mm"), 
                                                endTime : dayjs(lesson?.endTime, "HH:mm"), 
                                                date : reportPeriod[0].clone().add(moment(day.day, 'ddd').day(), "days").format("YYYY-MM-DD"),
                                                classId : c?.id,
                                                originalClassId : c?.id,
                                                day : day.day,
                                                className : c?.name
                                            }
                                        })}
                                        title="Double click to add another lesson on same time slot"
                                        style={{ 
                                            width : todayLessons.length > 1 ? 48 : "100%" , 
                                            textAlign : "center",
                                            marginInline : 1,
                                            backgroundColor : slot?.breakName  ? 
                                                "#cfc6c6"
                                                :
                                                lesson?.status === "approved"?
                                                "rgb(145 239 162)"
                                                :
                                                lesson?.status === "attended"?
                                                "#9f9fda"
                                                :   
                                                lesson?.status === "scheduled"?
                                                "rgb(221 234 221)"
                                                :
                                                lesson?.status === "cancelled"?
                                                "rgb(221 139 140)"
                                                :
                                                "",
                                            border : selectedItems.includes(lesson?.id)  && '0.5px solid black',
                                            cursor : "pointer"
                                        }}
                                        onClick={()=>selectedItems.includes(lesson?.id)? 
                                            setSelected(selectedItems.filter( id => id !== lesson?.id ))
                                            :
                                            setSelected([...selectedItems, lesson?.id])
                                        }
                                        >
                                        {lesson?.subject?.code}
                                        </Tag>
                                    )
                                }
                            </div>
                            :
                            <Button
                            style={{
                                height : 22
                            }}
                            onClick={() => setShowAddLessonModal({
                                show : true,
                                data : {
                                    startTime : dayjs(slot.startTime, "HH:mm"), 
                                    endTime : dayjs(slot?.endTime, "HH:mm"), 
                                    date : reportPeriod[0].clone().add(moment(day.day, 'ddd').day(), "days").format("YYYY-MM-DD"),
                                    classId : c?.id,
                                    originalClassId : c?.id,
                                    day : day.day,
                                    className : c?.name
                                }
                            })}
                            >
                                <PlusCircleOutlined/>
                            </Button>
                        }
                        
                    </td>
                }))
                
            }
            </tr> 
            }
            )
        }
    </tbody>
    </table>
}


   const firstColumn = {
    padding: 4,fontSize: 12, textAlign: 'left',left: 0, zIndex: 999, position: 'sticky', 
    backgroundColor: 'aliceblue',
}


