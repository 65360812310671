import {DatePicker} from 'antd'
import dayjs from 'dayjs';
import moment from "moment"
import { CalendarOutlined } from '@ant-design/icons';
const { RangePicker } = DatePicker;


const DatePickerComponent = ({reportPeriod, setReportPeriod, style, showIcon }) => {

    const dateFormat = 'DD/MM/YYYY';

    return (
        <div >
            <RangePicker
            allowClear={false}
            style={{...style}}
            value={(reportPeriod[0] && reportPeriod[1] ) ? [
                dayjs(moment(reportPeriod[0]).format("DD/MM/YYYY"), dateFormat ), 
                dayjs(moment(reportPeriod[1]).format("DD/MM/YYYY"), dateFormat)
            ] : []}
            onChange={ (value) => {
                setReportPeriod([
                    moment( dayjs(value[0]).format(dateFormat), dateFormat ),
                    moment( dayjs(value[1]).format(dateFormat), dateFormat ),
                ])
            }} 
            suffixIcon={ showIcon === false ? null : <CalendarOutlined/>}
        />
        </div>
    )
}

export default DatePickerComponent;