import {Row, Col, Card, Button, List, Skeleton, Avatar, message, Modal } from "antd"
import { useCallback, useEffect, useState } from "react";
import { requestError } from "../controllers/functions";
import axios from "axios"
import OrganizationUserModal from "../components/modals/OrganizationUser";
import OrganizationUserPermissionsModal from "../components/modals/Organization.User.Permissions";
import OrganizationInviteSchoolModal from "../components/modals/Organization.Invite.School";
import OrganizationUserSchoolsModal from "../components/modals/Organization.User.Schools";

const Organization = () => {
    const [ loading , setLoading ] = useState(false);
    const [ action , setAction ] = useState("create");
    const [ record , setRecord ] = useState(null);
    const [ organization , setOrganization ] = useState({});
    const [ openUserModal , setOpenUserModal ] = useState(false);
    const [ openOrganizationInviteSchoolModal , setOpenOrganizationInviteSchoolModal ] = useState(false);
    const [ openUserPermissionsModal , setOpenUserPermissionsModal ] = useState(false);
    const [ openUserSchoolsModal , setOpenUserSchoolsModal ] = useState(false);
    const [ users , setUsers ] = useState([]);
    const [ schools , setSchools ] = useState([]);
    const headers = { authorization : `Bearer ${localStorage.getItem("token")}`}

    const refresh = useCallback(async (v) => {
        try {
            setLoading(true);
            const  [ users, organization, schools ] = await Promise.all([
                axios.get(process.env.REACT_APP_API_URI + "/organization/users", {headers}),
                axios.get(process.env.REACT_APP_API_URI + "/organization/", {headers}),
                axios.get(process.env.REACT_APP_API_URI + "/organization/my/schools", {headers}),
            ]);
            setUsers(users.data);
            setOrganization(organization.data);
            setSchools(schools.data);
        } catch (error) {
            requestError({error}); 
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect( () => {
        refresh()
    }, [refresh])

    const deleteUser = (id) => {
        axios.delete(process.env.REACT_APP_API_URI + `/organization/users/${id}`, {headers}).then( done =>{
            message.success(done.data.res);
            refresh();
        }).catch( error => {
            requestError({error})
        }).finally( done => {
            setLoading(false)
        })
    }

    const enterIntoSchool = (id) => {
        axios.get(process.env.REACT_APP_API_URI + `/organization/schools/enter/${id}`, {headers}).then( done =>{
            message.success(done.data.res);
            localStorage.setItem("organization", "org" );
            localStorage.setItem("token", done.data.token);
            localStorage.setItem("user", `${done.data.user.firstName} ${done.data.user.lastName}`);
            window.location = "/teacher-dashboard";
  
        }).catch( error => {
            requestError({error})
        }).finally( done => {
            setLoading(false)
        })
    }
    
    return <>
    <Row style={{padding : "2rem", paddingBottom:0}}>
        <div style={{ width : "100%"}}>
            <Button type="danger" danger style={{float : "right"}} onClick={()=>{
                localStorage.clear(); 
                window.location = '/'
            }}>Logout</Button>
        </div>
    </Row>
    <h3 style={{textAlign:"center"}}>Welcome to {organization?.name}</h3>

    <Row style={{padding : "2rem", justifyContent : "center"}} gutter={25}>
        
        <Col lg={8} md={12} sm={24} xs={24}>
            <Card
            title="Our Schools"
            extra={<Button style={{ height : 27 }} onClick={()=>{setAction("create"); setOpenOrganizationInviteSchoolModal(true)}}>
                Invite a School
            </Button>}
            >
                <List
                className="demo-loadmore-list"
                loading={loading}
                itemLayout="horizontal"
                dataSource={schools}
                renderItem={(item) => (
                    <List.Item
                    onClick={() => enterIntoSchool(item?.id)}
                    style={{ cursor : "pointer" }}
                    extra={<div className="org-user-actions" >
                            <div key="list-loadmore-delete" style={{ color: "green", fontWeight:600 }}>Enter into school</div>
                    </div>}
                    >
                        <Skeleton avatar title={false} loading={loading} active>
                            <List.Item.Meta
                            avatar={<Avatar 
                                src={`${process.env.REACT_APP_API_URI}/s3?token=${localStorage.getItem("token")}&file=${item.logo}`}
                                shape="square"
                            />}
                            title={<div >{item.name }</div>}
                            description={`${item?.address}`}
                            />
                        </Skeleton>
                    </List.Item>
                )}
                />
            </Card>
        </Col>

        <Col lg={8} md={12} sm={24} xs={24}>
        <Card
        title="Our Users"
        extra={<Button style={{ height : 27 }} onClick={()=>{setAction("create"); setOpenUserModal(true); setRecord({})}}>
            Add User
        </Button>}
        >
            <List
            className="demo-loadmore-list"
            loading={loading}
            itemLayout="horizontal"
            dataSource={users}
            renderItem={(item) => (
                <List.Item
                extra={<div className="org-user-actions" >
                        <div style={{fontWeight:600, cursor : "pointer" }} onClick={() => { setOpenUserModal(true); setAction("edit") ; setRecord(item)}} key="list-loadmore-edit" >edit</div>
                        <div style={{ color: "green", fontWeight:600 , cursor : "pointer" }} onClick={() => { setOpenUserPermissionsModal(true) ; setRecord(item) }} key="list-loadmore-permissions">permissions</div>
                        <div style={{ color: "purple", fontWeight:600, cursor : "pointer" }} onClick={() => { setOpenUserSchoolsModal(true); setAction("edit"); setRecord(item) }} key="list-loadmore-schools">schools</div>
                        <div 
                        onClick={()=>{
                            Modal.confirm({
                            title  : "Are you sure you what to delete this user?",
                            onCancel : ()=>{},
                            onOk :  ()=> deleteUser(item?.id)
                          })}} 
                        key="list-loadmore-delete" style={{ color: "red", fontWeight:600, cursor : "pointer"  }}>delete</div>
                  </div>}
                  >
                    <Skeleton avatar title={false} loading={loading} active>
                        <List.Item.Meta
                        avatar={<Avatar src={item?.profilePicture} />}
                        title={<div style={{ cursor : "pointer" }}>{item.firstName } {item.lastName }</div>}
                        description={`${item?.email}`}
                        />
                    </Skeleton>
                </List.Item>
            )}
            />
        </Card>
        </Col>

    </Row>
    <OrganizationUserModal record={record} refresh={refresh} setOpenModal={setOpenUserModal} show={openUserModal} action={action}/>
    <OrganizationInviteSchoolModal record={record} refresh={refresh} setOpenModal={setOpenOrganizationInviteSchoolModal} show={openOrganizationInviteSchoolModal} action={action}/>
    <OrganizationUserPermissionsModal record={record} refresh={refresh} setOpenModal={setOpenUserPermissionsModal} show={openUserPermissionsModal}/>
    <OrganizationUserSchoolsModal record={record} refresh={refresh} setOpenModal={setOpenUserSchoolsModal} show={openUserSchoolsModal}/>
    </>
}

export default Organization;