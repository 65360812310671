

import { Button, Modal, TimePicker, Input, Form, Select, message, Col, Row, DatePicker, List,  } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';

import { requestError } from '../../controllers/functions';
import { DeleteOutlined, EditOutlined, PlusCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import DatePickerComponent from '../date/period';
import Title from 'antd/es/typography/Title';
import moment from 'moment';

const TimetableAddLessonModal = ({ show, setOpenModal, refresh, lesson, timetableId }) => {

  const [form] = Form.useForm();
  
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [classes, setClasses] = useState([]);
  const [ subjects, setSubjects] = useState([]);
  const [state, setState] = useState({});
  const [classSubjects, setClassSubjects] = useState([]);
    const [reportPeriod , setReportPeriod] = useState([]);
  
  

  useEffect(() => {
    if(show){
        setClassSubjects([]);
        setReportPeriod([])
        setState(lesson)
        form.resetFields();
        form.setFieldsValue(lesson);
        getApiFeeds(lesson);
    }
  }, [form, lesson, show]);

  const getApiFeeds = async ({ startTime, endTime, classId }) => {
    const headers = {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    setLoading(true)
  
    try {
        const lessonQueryVariables = new URLSearchParams({
            ...lesson,
            startTime : startTime.format("HH:mm"),
            endTime : endTime.format("HH:mm"),
            classId
        }).toString();

      const [classesResponse, subjectsResponse, timetableDateRange ] = await Promise.all([
        axios.get(
            `${process.env.REACT_APP_API_URI}/teacher/timetable/free/classes?timetableId=${timetableId}&${lessonQueryVariables}`, { headers }
        ),
        axios.get(`${process.env.REACT_APP_API_URI}/teacher/timetable/free/subjects?timetableId=${timetableId}&${lessonQueryVariables}`, { headers }),
        axios.get(`${process.env.REACT_APP_API_URI}/teacher/timetable/date/range`, { headers }),
      ]);

      if( classesResponse.data.length === 0 ) {
        form.resetFields(['classId']);
        setState({
            ...state,
            classId : null
        })
      }
  
      setClasses(classesResponse.data);
      setSubjects(subjectsResponse.data);
      setReportPeriod([ moment(timetableDateRange.data[0]), moment(timetableDateRange.data[1]) ]);
      
    } catch (error) {
      requestError({ error });
    } finally {
        setLoading(false);
    }
  };
  
  if(show !== isModalVisible){
    setIsModalVisible(show);
  }

  const submit = (v) =>{

    if(classSubjects.length === 0 ) {
        return message.warning("You need to add class subjects to be scheduled")
    }

    if(reportPeriod.length === 2 ) {
        setLoading(true)
        axios.post(process.env.REACT_APP_API_URI+"/teacher/timetable", 
            {
              startTime : v?.startTime?.format("HH:mm:ss"),
              endTime : v?.endTime?.format("HH:mm:ss"),
              timetableId,
              day : lesson.day,
              startDate : reportPeriod[0].format("YYYY-MM-DD"),
              endDate : reportPeriod[1].format("YYYY-MM-DD"),
              subjects : classSubjects.map( cs => ({ classId : cs?.class?.id, subjectId : cs?.subject?.id}))
            }
            , {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}} ).then(res=>{
              message.success(res.data.res)
              setOpenModal(false);
              refresh();
            }).catch(error=>{
              requestError({error});
            }).finally(done=>{
                setLoading(false);
            })
    }else{
        message.warning("Please select start date and end date")
    }

  }

  const deleteRecord = ({ subjectId, classId }) =>{
    setClassSubjects(
        classSubjects.filter( x => x.class.id !== classId && x.subject.id !== subjectId )
    )
  }

  const [loading, setLoading] = useState(false)

  return (
    <>
      <Modal 
      title={`ADD ${lesson?.day} LESSON FOR ${lesson?.className}`.toUpperCase() }
      open={isModalVisible} 
      onOk={()=>setOpenModal(false)} 
      onCancel={()=>setOpenModal(false)}
      footer={[
        <Button key="cancel" type=""  onClick={()=>{setOpenModal(false)}}>
            Cancel
        </Button>,
        <Button  key="submit" type="primary" htmlType="submit" form="timetableConfigForm" loading={loading}>
          Submit
        </Button>     
      ]}
      >
      <Form
      layout="vertical"
      className="row-col"
      onFinish={(v)=> submit(v) }
      id="timetableConfigForm" 
      form={form}
      >
        <Row>
            <Col 
                    span={12}
                    style={{paddingLeft:5}} 
                    >
                        <Form.Item >
                            <DatePickerComponent
                                style={{ height: 29, fontSize : 12 }}
                                reportPeriod={reportPeriod}
                                setReportPeriod={setReportPeriod}
                            />
                        </Form.Item>
                </Col>
            <Col 
                span={6}
                style={{paddingLeft:5}} 
                >
                <Form.Item 
                    name={`startTime`}
                    rules={[
                    {
                        required: true,
                        message: "Required Field*",
                    },
                    ]}
                    required
                    >
                    <TimePicker format={"HH:mm"} style={{width : "100%", borderRadius : 6}}  
                    onCalendarChange={(value)=>{
                           
                        setState({
                            ...state,
                            classId : null,
                            subjectId: null
                        })
                        form.resetFields(['classId', 'subjectId'])

                        setClassSubjects([])

                        setState({ ...state, startTime : value && value.format('HH:mm') }); 
                        getApiFeeds({
                            ...state,
                            endTime : dayjs( state?.endTime, "HH:mm:ss"),
                            startTime : value
                        })

                        }} 
                        placeholder="Start Time" 
                        />
                </Form.Item>
            </Col>

            <Col  
                span={6} 
                style={{paddingLeft:5}}
                >
                <Form.Item 
                name={`endTime`}
                    rules={[
                    {
                        required: true,
                        message: "Required Field*",
                    },
                    ]}
                    required
                >
                    <TimePicker
                    format={"HH:mm"} 
                    style={{width : "100%", borderRadius : 6}}  
                    onCalendarChange={(value)=>{
                        
                        setState({
                            ...state,
                            classId : null,
                            subjectId: null
                        })
                        
                        form.resetFields(['classId', 'subjectId'])

                        setClassSubjects([])

                        setState({ ...state, endTime : value && value.format('HH:mm') }); 
                        getApiFeeds({
                            ...state,
                            startTime : dayjs( state?.startTime, "HH:mm:ss"),
                            endTime : value
                        })

                    }
                    } 
                    placeholder="End Time" 
                    />
                </Form.Item>
            </Col>

        </Row>
        <Form.Item>
        <List
            size="small"
            bordered
            dataSource={classSubjects}
            renderItem={(item) => (
                <List.Item style={{ width : "100%"}}>
                    <Row style={{ width : "100%"}}>
                        <Col span={20} >
                            {item?.subject?.name} ( {item?.class?.name} )
                        </Col>
                        <Col span={4} >
                            <div style={{textAlign :"right", width : "100%"}}>
                            <DeleteOutlined 
                                onClick={()=>deleteRecord({ subjectId : item?.subject?.id, classId : item?.class?.id })}
                                style={{ color : "red"}}
                                />
                            </div>
                        </Col>
                    </Row>
                </List.Item>
            )}
        />
        </Form.Item>

        <Row>

        <Col span={11} >
            <Form.Item
                style={{marginRight: 5}}
                name={'classId'}
                required
                >
                <Select
                    showSearch
                    onChange={(value)=>{
                        setState({ ...state, classId : value });
                        getApiFeeds({
                            ...state,
                            startTime : dayjs( state?.startTime, "HH:mm:ss"),
                            endTime : dayjs( state?.endTime, "HH:mm:ss"),
                            classId : value
                        })
                    }}
                    loading={loading}
                    value={state?.classId}
                    placeholder="Select class"
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={classes.map( c => ({ label : c?.name, value : c?.id }))}
                />
                
                </Form.Item>
            </Col>

        <Col span={11} >
            <Form.Item
                style={{marginRight: 5}}
                name={'subjectId'}
                required
                >
                <Select
                    showSearch
                    loading={loading}
                    onChange={(value)=>{
                        const subject = subjects.find( s => s.id === value && s.isTeacherFree === false );
                        if(subject) {
                            Modal.confirm({
                                icon: null,
                                title  : <div
                                style={{
                                    textAlign : "center"
                                }}>
                                <div style={{ fontSize : 17, fontWeight : 600 }}>Conflict</div>
                                <div style={{ fontWeight : 400 , fontSize : 13 }}>
                                {`${subject?.lesson?.teacher?.tittle} ${subject?.lesson?.teacher?.lastName} is teaching ${subject?.lesson?.subject?.name} in ${subject?.lesson?.class?.name} during ${subject?.lesson?.startTime} to  ${subject?.lesson?.endTime} on ${subject?.lesson?.day}.`}
                                </div>
                                </div>,
                                onCancel : ()=>{
                                    setState({ ...state, subjectId : null });
                                    form.resetFields(['subjectId'])
                                },
                                onOk :  ()=> setState({ ...state, subjectId : value })
                              })
                        }else{
                            setState({ ...state, subjectId : value });
                        }

                    }}
                    value={state?.subjectId}
                    placeholder="Select subject."
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={subjects.map( c => ({ label : c?.name, value : c?.id }))}
                />
                
                </Form.Item>
            </Col>
            <Col span={2}>
            <Form.Item>
            <Button
            style={{
                height : 33
            }}
            onClick={()=>{
                if ( state?.classId && state?.subjectId) {
                    const subject = subjects.find( x => x.id === state?.subjectId);
                const c = classes.find( x => x.id === state?.classId);

                setClassSubjects([
                    ...classSubjects,
                    {
                        class : c,
                        subject
                    }
                ])

                setState({
                    ...state,
                    classId : null,
                    subjectId: null
                })
                form.resetFields(['classId', 'subjectId'])
                }else{
                    message.error("Make sure you selected subject and class")
                }
                
            }}
            >
                <PlusCircleOutlined/>
            </Button>
            </Form.Item>
            </Col>
          
        </Row>

        </Form>
      </Modal>
    </>
  );
};

export default TimetableAddLessonModal;