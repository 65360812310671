

import { Button, Modal, TimePicker, Input, Form, Select, message, Col, Row, DatePicker,  } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';

import { requestError } from '../../controllers/functions';
import DatePickerComponent from '../date/period';

const TimetableAutoGenModal = ({ show, setOpenModal, refresh, timetable }) => {

  const [form] = Form.useForm();
  
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [timetables, setTimetables] = useState([]);
  const [state, setState] = useState({});
  const [reportPeriod , setReportPeriod] = useState([]);
  

  useEffect(() => {
    setReportPeriod([])
    form.resetFields();
    setLoading(true)
    axios.get(process.env.REACT_APP_API_URI + "/teacher/timetable/timetables", {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}}).then(data =>{
        setTimetables(data.data);
      }).catch(error =>{
          requestError({error});
      }).finally( done =>{
        setLoading(false)
      })
  }, [show]);

  if(show !== isModalVisible){
    setIsModalVisible(show);
  }

      const autoGenerateTimeTable = (v) => {
        
          axios.post(process.env.REACT_APP_API_URI+`/teacher/timetable/auto/generate`, 
            {
                ...v,
                startDate : reportPeriod[0], 
                endDate : reportPeriod[1]
            }
            , 
            {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}} ).then(res=>{
            message.success(res.data?.res);
            setOpenModal(false);
            refresh();
          }).catch(error=>{
            requestError({error});
          })
    
      }

  const [loading, setLoading] = useState(false)
  

  return (
    <>
    
      <Modal 
      title={`TIMETABLE AUTO GENERATION` }
      loading={loading}
      open={isModalVisible} 
      onOk={()=>setOpenModal(false)} 
      onCancel={()=>setOpenModal(false)}
      footer={[
        <Button key="cancel" type=""  onClick={()=>{setOpenModal(false)}}>
            Cancel
        </Button>,
        <Button  key="submit" type="primary" htmlType="submit" form="timetableAutoGenForm" loading={loading}>
          Submit
        </Button>     
      ]}
      >
      <Form
      layout="vertical"
      className="row-col"
      onFinish={(v)=> autoGenerateTimeTable(v)}
      id="timetableAutoGenForm" 
      form={form}
      >

        <Row>

          <Col span={12} >
          <Form.Item
                style={{marginRight: 5}}
                name={'timetableId'}
                rules={[
                    {
                        required: true,
                        message: "Required Field*",
                    },
                    ]}
                required
                >
                <Select
                    showSearch
                    onChange={(value)=>{
                        setState({ ...state, timetableId : value });
                    }}
                    value={state?.timetableId}
                    placeholder="Select timetable."
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={timetables.map( c => ({ label : c?.name, value : c?.id }))}
                />
                
                </Form.Item>
          </Col>
          
          <Col 
                span={12}
                style={{paddingLeft:5}} 
                >
                <DatePickerComponent
                    style={{ height: 29, fontSize : 12 }}
                    reportPeriod={reportPeriod}
                    setReportPeriod={setReportPeriod}
                />
            </Col>

        </Row>

        </Form>
      </Modal>
    </>
  );
};

export default TimetableAutoGenModal;