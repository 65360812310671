import { Button, Modal, Form, Select, message, Col, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { requestError } from '../../controllers/functions';

const { Option } = Select;

const OrganizationUserSchoolsModal = ({ show, setOpenModal, record, refresh }) => {

  const [form] = Form.useForm();
  const headers = { authorization : `Bearer ${localStorage.getItem("token")}`}
  
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [state, setState] = useState({});
  const [schools, setSchools ] = useState([]);

  useEffect(() => {
    setState({...record});
  }, [show ]);

  if(show !== isModalVisible){
    setIsModalVisible(show);
  }

  const submit = () =>{
    setLoading(true);
    axios.post(process.env.REACT_APP_API_URI+`/organization/users/schools/${record?.id}`, {
        schools : state?.schools,
        organizationUserId : record?.id
    } , {headers} ).then(res=>{
      message.success(res.data.res)
      setOpenModal(false);
      refresh();
    }).catch(error=>{
      requestError({error});
    }).finally(done=>{
        setLoading(false);
    })
  }

  const getSchools = () => {
    setLoading(true);
    axios.get(process.env.REACT_APP_API_URI + "/organization/schools", {headers} ).then(({data}) => {
        setSchools(data)
    }).catch( error =>{
        requestError({error})
    }).finally(done=>{
        setLoading(false);
    })
  }

  const getUserSchools = () => {
    setLoading(true);
    axios.get(process.env.REACT_APP_API_URI+`/organization/users/schools/${record?.id}`, {headers} ).then(({data}) => {
        const values = data.map( item => item.id )
        setState({
            ...state,
            schools : values
        })
        form.setFieldsValue({schools : values })
    }).catch( error =>{
        requestError({error})
    }).finally(done=>{
        setLoading(false);
    })
  }

  useEffect( () =>{
    if(show){
        getSchools();
        getUserSchools();
    }
  }, [show])

  const [loading, setLoading] = useState(false)

  return (
    <>
    
      <Modal 
      title={`USER ACCOUNT schools`}
      open={isModalVisible} 
      loading = {loading}
      onOk={()=>setOpenModal(false)} 
      onCancel={()=>setOpenModal(false)}
      footer={[
        <Button key="cancel" type=""  onClick={()=>{setOpenModal(false)}}>
            Cancel
        </Button>,
        <Button  key="submit" type="primary" htmlType="submit" form="org-user-schools" loading={loading}>
          Save Changes
        </Button>     
      ]}
      >
      <Form
      layout="vertical"
      className="row-col"
      onFinish={(e)=> submit({isDraft : false}) }
      id="org-user-schools" 
      form={form}
      >
        <Row>
          <Col span={24} >
            <Form.Item
            name = "schools" 
            style={{marginRight: 5}}
            >
              <Select className='m-0'
                        mode="multiple"
                        showSearch
                        value={state.schools}
                        onChange={(schools)=>{
                          setState({
                            ...state,
                            schools 
                          });
                        }}
                        placeholder="Schools.."
                        optionFilterProp="children"
                    
                      >
                        {
                          schools?.map(item =>
                            <Option  key={item.id} value={item.id}>{item.name}</Option>
                            )
                        }
                      </Select>
            </Form.Item>
          </Col>
          
        </Row>
        </Form>

      </Modal>
    </>
  );
};

export default OrganizationUserSchoolsModal;