import { Button, Modal, Input, Form, Select, message, Col, Row, DatePicker } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Avatar, List, Radio, Space } from 'antd';

import { requestError } from '../../controllers/functions';

const ParentStudentsModal = ({ show, setOpenModal, parent }) => {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [students, setStudents] = useState([]);

  useEffect(() => {
    refresh();
  }, [show ]);

  if(show !== isModalVisible){
    setIsModalVisible(show);
  }

  const refresh = () => {
    setLoading(true);
    axios.get(process.env.REACT_APP_API_URI+`/parents/students?parentId=${parent?.id}`, {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}}).then(data =>{
      setStudents(data.data);
    }).catch(error =>{
      requestError({error})
    }).finally( done =>{
        setLoading(false)
    })
  }

  const [loading, setLoading] = useState(false)


  return (
    <>
    
      <Modal 
      title={`${parent?.name+"`s"} CHILDREN`.toUpperCase() }
      open={isModalVisible} 
      onOk={()=>setOpenModal(false)} 
      onCancel={()=>setOpenModal(false)}
      footer={[
        <Button key="cancel" type=""  onClick={()=>{setOpenModal(false)}} danger>
            Close
        </Button>
      ]}
      loading={loading}
      >
        <Students data={students}/>
      </Modal>
    </>
  );
};

export default ParentStudentsModal;

const Students = ({ data }) => {
  return (
    <>
      <List
        dataSource={data}
        renderItem={(item, index) => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar src={`${process.env.REACT_APP_API_URI}/s3?token=${localStorage.getItem("token")}&file=${item.profilePicture}`} />}
              title={<>{item.lastName} {item.firstName}</>}
              description={
                <span>{item?.class?.name}</span>
              }
            />
          </List.Item>
        )}
      />
    </>
  );
};
